exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-all-price-lists-index-tsx": () => import("./../../../src/pages/all-price-lists/index.tsx" /* webpackChunkName: "component---src-pages-all-price-lists-index-tsx" */),
  "component---src-pages-blog-wp-blog-post-slug-tsx": () => import("./../../../src/pages/blog/{WpBlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-wp-blog-post-slug-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-incline-conveyors-tsx": () => import("./../../../src/pages/incline-conveyors.tsx" /* webpackChunkName: "component---src-pages-incline-conveyors-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-price-list-tsx": () => import("./../../../src/pages/my-price-list.tsx" /* webpackChunkName: "component---src-pages-my-price-list-tsx" */),
  "component---src-pages-shipping-and-return-policy-tsx": () => import("./../../../src/pages/shipping-and-return-policy.tsx" /* webpackChunkName: "component---src-pages-shipping-and-return-policy-tsx" */),
  "component---src-templates-models-archive-tsx": () => import("./../../../src/templates/models-archive.tsx" /* webpackChunkName: "component---src-templates-models-archive-tsx" */),
  "component---src-templates-modifications-archive-tsx": () => import("./../../../src/templates/modifications-archive.tsx" /* webpackChunkName: "component---src-templates-modifications-archive-tsx" */),
  "component---src-templates-product-variant-card-tsx": () => import("./../../../src/templates/product-variant-card.tsx" /* webpackChunkName: "component---src-templates-product-variant-card-tsx" */)
}

