import { cn } from "@/lib/utils";
import React from "react";

interface DesignersProps {
  className?: string;
}

function Designers({ className }: DesignersProps) {
  return (
    <p
      className={cn(
        "m-0 font-prompt text-base align-bottom text-textPrimary",
        className
      )}
    >
      Developed by{" "}
      <a
        href="https://linkedin.com/in/fiodar-savutsin/"
        target="_blank"
        rel="noreferrer noopener"
        className="text-text-primary underline"
      >
        {" "}
        Fiodar Savutsin
      </a>
      <br />
      {/* ⚪🔴⚪ */}
    </p>
  );
}

export default Designers;
