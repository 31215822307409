import React, { FC } from "react";
import { Link } from "gatsby";
import ContactList from "./ContactListFooter";
import Designers from "./Designers";
import { StaticImage } from "gatsby-plugin-image";
import { cn } from "@/lib/utils";

interface FooterProps {
  contactInfo: ContactInfo;
  logoOld: string;
}

const Footer: FC<FooterProps> = ({ contactInfo }) => {
  return (
    <footer className="relative overflow-hidden w-full bg-[rgba(236,201,77,0.69)] p-3 px-5 max-[22.5rem]:px-3">
      <div className="max-w-(--breakpoint-mui-md) mx-auto">
        <div className="grid grid-cols-[3fr_1fr] mui-md:grid-cols-[2fr_auto_1fr] gap-x-12 flex-nowrap items-center justify-between">
          <ContactList contactInfo={contactInfo} />
          <div className="ml-auto">
            <Link to="/" className="inline-block">
              <StaticImage
                src="../../../images/logo-old.png"
                placeholder="dominantColor"
                layout="constrained"
                width={114}
                className="block w-21 h-auto mui-md:w-28.5"
                alt="Farm equipment logo"
              />
            </Link>
          </div>
          <ShopLinksAndDesigners />
        </div>
      </div>
    </footer>
  );
};

const ShopLinksAndDesigners: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={cn(
        "flex flex-col items-start justify-center text-left",
        className
      )}
    >
      <Link
        to="/shipping-and-return-policy#shipping"
        className="inline-block mt-4 underline hover:text-white text-textPrimary font-prompt not-italic text-base leading-[1.6]"
      >
        Shipping & Return Policy
      </Link>
      <Link
        to="/shipping-and-return-policy#payment"
        className="inline-block mt-4 underline hover:text-white text-textPrimary font-prompt not-italic text-base leading-[1.6]"
      >
        Payment policy
      </Link>
      <Designers className="mt-4" />
    </div>
  );
};

export default Footer;
