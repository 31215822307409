import React, { useContext, useState, useMemo, ReactNode } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useStaticQuery, graphql } from "gatsby";
import { HeaderHeightStateContext } from "../../context/headerHeightContext";
import clsx from "clsx";
import { WindowLocation } from "@reach/router";

const Layout = ({
  location,
  children,
}: {
  location: WindowLocation;
  children: ReactNode;
}) => {
  const {
    // wp: {
    //   generalSettings: { title },
    // },
    allWpContactInfoItem: {
      settingList: [firstSettingGroup],
    },
    logoOld,
    fep,
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
      allWpContactInfoItem(limit: 1) {
        settingList: nodes {
          contact_info {
            addressLink
            addressLinkExternal
            addressText
            facebookLink
            instagramLink
            instagramName
            youtubeLink
            youtubeName
            email
            fieldGroupName
            phone
            phoneText
            workingTime
          }
        }
      }
      logoOld: file(relativePath: { eq: "logo-old.png" }) {
        publicURL
      }
      fep: file(relativePath: { eq: "FEP.svg" }) {
        publicURL
      }
    }
  `);
  const { contact_info: contactInfo } = firstSettingGroup;
  const [scrolled, setScrolled] = useState(false);
  const headerHeight = useContext(HeaderHeightStateContext);
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const isHomePage = useMemo(() => location.pathname === "/", [location]);

  return (
    <div
      data-is-root-path={isHomePage}
      className={clsx(
        "flex flex-col min-h-screen bg-white",
        !isHomePage &&
          "mui-md:bg-no-repeat bg-[#f7f7f7] bg-[bottom_-33px_left_calc(50%+120px)]"
      )}
      style={{
        backgroundImage:
          !isHomePage && matchesDesktop ? `url(${fep.publicURL})` : "none",
      }}
    >
      <Header
        contactInfo={contactInfo}
        logoOld={logoOld.publicURL}
        location={location}
        scrolled={scrolled}
        setScrolled={setScrolled}
      />
      <main
        className="grow min-h-screen"
        style={{
          marginTop: !isHomePage && scrolled ? headerHeight : 0,
        }}
      >
        {children}
      </main>

      <Footer contactInfo={contactInfo} logoOld={logoOld.publicURL} />
    </div>
  );
};

export default Layout;
