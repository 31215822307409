import React, { FC } from "react";
import EnvelopeIcon from "@/assets/icons/envelope_secondary.svg";
import PhoneIcon from "@/assets/icons/phone_secondary.svg";
import TimeIcon from "@/assets/icons/time_secondary.svg";
import FacebookIcon from "@/assets/icons/facebook_secondary.svg";
import AddressIcon from "@/assets/icons/address_primary.svg";
import InstagramIcon from "@/assets/icons/instagram_secondary.svg";
import YoutubeIcon from "@/assets/icons/youtube_secondary.svg";
import { cn } from "@/lib/utils";

interface ContactListProps {
  contactInfo: ContactInfo;
  className?: string;
}

const ContactListDesktop: FC<ContactListProps> = ({
  contactInfo,
  className,
}) => {
  return (
    <ul
      className={cn(
        "grid m-0 p-0 list-none gap-4 mui-md:grid-cols-[auto_auto]",
        className
      )}
    >
      <li className="flex flex-col justify-between h-full">
        <p className="text-textPrimary p-0 m-0">
          <a
            href={contactInfo.addressLinkExternal}
            target="_blank"
            rel="noreferrer noopener"
            aria-label="address"
            className="group hover:text-white no-underline text-textPrimary pl-1"
          >
            <AddressIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
            <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
              {contactInfo.addressText}
            </span>
          </a>
        </p>
      </li>
      <li className="flex flex-col justify-between h-full">
        <p className="text-textPrimary p-0 m-0">
          <a
            href={`callto:${contactInfo.phone}`}
            aria-label="phone"
            className="group hover:text-white no-underline text-textPrimary pl-1"
          >
            <PhoneIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
            <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
              {contactInfo.phoneText}
            </span>
          </a>
        </p>
      </li>
      <li className="flex flex-col justify-between h-full">
        <p className="text-textPrimary p-0 m-0">
          <a
            href={`mailto:${contactInfo.email}`}
            aria-label="email"
            className="group hover:text-white no-underline text-textPrimary pl-1"
          >
            <EnvelopeIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
            <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
              {contactInfo.email}
            </span>
          </a>
        </p>
      </li>
      <li className="flex flex-col justify-between h-full">
        <p className="text-textPrimary p-0 m-0">
          <a
            href={`mailto:${contactInfo.instagramLink}`}
            aria-label="instagram"
            className="group hover:text-white no-underline text-textPrimary pl-1"
          >
            <InstagramIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
            <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
              {contactInfo.instagramName}
            </span>
          </a>
        </p>
      </li>
      <li className="flex flex-col justify-between h-full">
        <p className="text-textPrimary p-0 m-0">
          <a
            href={`mailto:${contactInfo.youtubeLink}`}
            aria-label="youtube"
            className="group hover:text-white no-underline text-textPrimary pl-1"
          >
            <YoutubeIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
            <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
              {contactInfo.youtubeName}
            </span>
          </a>
        </p>
      </li>
    </ul>
  );
};

const ContactListMobile: FC<ContactListProps> = ({
  contactInfo,
  className,
}) => {
  return (
    <ul
      aria-label="contact us"
      className={cn("flex m-0 p-0 justify-between list-none", className)}
    >
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={`mailto:${contactInfo.email}`}
          aria-label="email"
          className="group hover:text-white no-underline text-textPrimary pl-1"
        >
          <EnvelopeIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
          <span className="hidden mui-md:inline font-prompt not-italic text-base leading-[1.6] align-bottom">
            {contactInfo.email}
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0 hidden mui-md:list-item">
        <a className="group hover:text-white no-underline text-textPrimary pl-1">
          <TimeIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
          <span className="hidden mui-md:inline font-prompt not-italic text-base leading-[1.6] align-bottom">
            {contactInfo.workingTime}
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={`callto:${contactInfo.phone}`}
          aria-label="phone"
          className="group mui-md:mb-4 hover:text-white no-underline text-textPrimary pl-1"
        >
          <PhoneIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
          <span className="hidden mui-md:inline font-prompt not-italic text-base leading-[1.6] align-bottom">
            {contactInfo.phoneText}
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={contactInfo.facebookLink}
          target="_blank"
          aria-label="facebook"
          rel="noreferrer noopener"
          className="group hover:text-white no-underline text-textPrimary pl-1"
        >
          <FacebookIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
          <span className="hidden mui-md:inline font-prompt not-italic text-base leading-[1.6] align-bottom">
            our facebook link
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={contactInfo.instagramLink}
          target="_blank"
          aria-label="facebook"
          rel="noreferrer noopener"
          className="group hover:text-white no-underline text-textPrimary pl-1"
        >
          <InstagramIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
          <span className="hidden mui-md:inline font-prompt not-italic text-base leading-[1.6] align-bottom">
            our instagram link
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={contactInfo.youtubeLink}
          target="_blank"
          aria-label="facebook"
          rel="noreferrer noopener"
          className="group hover:text-white no-underline text-textPrimary pl-1"
        >
          <YoutubeIcon className="mr-1 w-4.5 h-4.5 inline-block fill-textPrimary group-hover:fill-white" />
          <span className="hidden mui-md:inline font-prompt not-italic text-base leading-[1.6] align-bottom">
            our youtube link
          </span>
        </a>
      </li>
    </ul>
  );
};

const ContactList: FC<ContactListProps> = ({ contactInfo }) => (
  <>
    <ContactListDesktop
      contactInfo={contactInfo}
      className="max-mui-md:hidden"
    />
    <ContactListMobile contactInfo={contactInfo} className="mui-md:hidden" />
  </>
);

export default ContactList;
