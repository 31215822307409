import React, { FC } from "react";
import EnvelopeIcon from "@/assets/icons/envelope_secondary.svg";
import PhoneIcon from "@/assets/icons/phone_secondary.svg";
import { cn } from "@/lib/utils";
import useDimensions from "react-cool-dimensions";
import TimeIcon from "@/assets/icons/time_secondary.svg";
import FacebookIcon from "@/assets/icons/facebook_secondary.svg";

interface ContactListProps {
  contactInfo: {
    email: string;
    phone: string;
    phoneText: string;
    workingTime: string;
    facebookLink: string;
  };
  variant: "horizontal" | "vertical";
  className?: string;
}

const ContactList: FC<ContactListProps> = ({
  contactInfo,
  variant,
  className,
}) => {
  const { observe, width } = useDimensions({
    onResize: ({ observe, unobserve }) => {
      unobserve();
      observe();
    },
  });

  return (
    <ul
      aria-label="contact us"
      className={cn(
        "m-0 p-0 items-center flex-row-reverse relative list-none",
        {
          block: variant === "vertical",
          flex: variant === "horizontal",
        },
        className
      )}
    >
      <ListItem
        className={cn({
          "max-420:hidden": variant === "horizontal",
        })}
      >
        <Link href={`mailto:${contactInfo.email}`} aria-label="email">
          <EnvelopeIcon
            className={"group-hover:fill-white inline-block mr-2 w-4.5 h-4.5"}
          />
          <span
            className={cn("align-middle leading-6 md:inline", {
              inline: variant === "vertical",
              hidden: variant === "horizontal",
            })}
          >
            {contactInfo.email}
          </span>
        </Link>
      </ListItem>
      <ListItem className={"max-desktop:hidden"}>
        <p className="group no-underline text-yellowPrimary pl-1">
          <TimeIcon
            className={"group-hover:fill-white inline-block mr-1 w-3.5 h-3.5"}
          />
          <span className="align-bottom leading-6">
            {contactInfo.workingTime}
          </span>
        </p>
      </ListItem>
      <ListItem>
        <Link
          ref={observe}
          href={`callto:${contactInfo.phone}`}
          aria-label="phone"
        >
          <PhoneIcon
            className={"group-hover:fill-white inline-block mr-2 w-4.5 h-4.5"}
          />
          <span className="align-middle leading-6">
            {contactInfo.phoneText}
          </span>
        </Link>
      </ListItem>
      <ListItem
        className={cn("mr-0 -bottom-3.5 -order-1 max-mui-md:hidden", {
          absolute: variant === "vertical",
          static: variant === "horizontal",
        })}
        style={{ left: width + 40 }}
      >
        <Link
          href={contactInfo.facebookLink}
          target="_blank"
          aria-label="facebook"
          rel="noreferrer noopener"
        >
          <FacebookIcon
            className={"group-hover:fill-white inline-block mr-1 w-3.5 h-3.5"}
          />
          <span className="align-bottom leading-6 hidden">facebook</span>
        </Link>
      </ListItem>
    </ul>
  );
};

const ListItem: React.FC<{
  children: React.ReactNode;
  ref?: React.LegacyRef<HTMLLIElement>;
  style?: React.CSSProperties;
  className?: string;
}> = ({ ref, style, children, className }) => {
  return (
    <li
      ref={ref}
      style={style}
      className={cn(
        "p-0 m-4 ml-0 font-prompt not-italic font-light text-base last:mr-0 text-yellowPrimary",
        className
      )}
    >
      {children}
    </li>
  );
};

const Link = React.forwardRef<
  HTMLAnchorElement,
  {
    children: React.ReactNode;
    className?: string;
  } & React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
>(({ children, className, ...props }, ref) => {
  return (
    <a
      ref={ref}
      className={cn(
        "inline-block no-underline text-yellowPrimary pl-1 hover:text-white group",
        className
      )}
      {...props}
    >
      {children}
    </a>
  );
});

export default ContactList;
